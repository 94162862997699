export const getVenetianMapData = () => {
    return [
        {
            mapBg: require('@/assets/maps/bg-venetian-route.jpg'),
            mapLine: 'VenetianPath',
            venueBg:  require('@/assets/maps/venetian.png'),
            venue: 'The Venetian & The Palazzo',
            distance: '3.7',
            singleLineVenue: true,
            threeLineVenue: false
        }
    ]
}
